<template>
    <main>
        <Navbar />
        <div class="main">
            <div class="container">
                <div class="btn-group w-100" role="group" aria-label="Button group with nested dropdown">
                    <button type="button" class="btn btn-secondary" @click.prevent="selectSport(1)" :class="{'active': sport == 1}" :disabled="sport == 1">
                        <i class="fas fa-futbol mr-2" v-show="platform == 'desktop'"></i>Futebol
                        <span class="badge badge-warning float-right" :class="{'w-100': platform == 'mobile'}" v-show="sport == 1 && events.length > 0">{{events.length}}</span>
                    </button>
                    <button type="button" class="btn btn-secondary" @click.prevent="selectSport(2)" :class="{'active': sport == 2}" :disabled="sport == 2">
                        <i class="fas fa-basketball-ball mr-2" v-show="platform == 'desktop'"></i>Basquete
                        <span class="badge badge-warning float-right" :class="{'w-100': platform == 'mobile'}" v-show="sport == 2 && events.length > 0">{{events.length}}</span>
                    </button>
                    <button type="button" class="btn btn-secondary" @click.prevent="selectSport(3)" :class="{'active': sport == 3}" :disabled="sport == 3">
                        <i class="fas fa-boxing-glove mr-2" v-show="platform == 'desktop'"></i>Lutas
                        <span class="badge badge-warning float-right" :class="{'w-100': platform == 'mobile'}" v-show="sport == 3 && events.length > 0">{{events.length}}</span>
                    </button>
                    <div class="btn-group" role="group">
                        <button id="btnGroupDrop1" type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fas fa-ellipsis-v mr-2"></i>Mais
                        </button>
                        <div class="dropdown-menu drop-left" aria-labelledby="btnGroupDrop1">
                            <a class="dropdown-item" href="javascript:void(0);" @click.prevent="selectSport(4)"><i class="fas fa-hockey-puck mr-2"></i>Hóquei</a>
                            <a class="dropdown-item" href="javascript:void(0);" @click.prevent="selectSport(5)"><i class="fas fa-volleyball-ball mr-2"></i>Voleibol</a>
                            <a class="dropdown-item" href="javascript:void(0);" @click.prevent="selectSport(6)"><i class="fas fa-tennis-ball mr-2"></i>Tênis</a>
                            <a class="dropdown-item" href="javascript:void(0);" @click.prevent="selectSport(7)"><i class="fas fa-gamepad-alt mr-2"></i>E-Sports</a>
                            <div class="dropdown-divider"></div>
                            <div class="dropdown-header text-center text-uppercase">Filtrar</div>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="javascript:void(0);" @click.prevent="search = ''"><i class="fas fa-filter mr-2"></i>Todos</a>
                            <a class="dropdown-item" href="javascript:void(0);" @click.prevent="search = 'Aguardando'"><i class="fas fa-filter mr-2"></i>Aguardando</a>
                            <a class="dropdown-item" href="javascript:void(0);" @click.prevent="search = 'Encerrado'"><i class="fas fa-filter mr-2"></i>Encerrado</a>
                            <a class="dropdown-item" href="javascript:void(0);" @click.prevent="search = 'Adiado'"><i class="fas fa-filter mr-2"></i>Adiado</a>
                            <a class="dropdown-item" href="javascript:void(0);" @click.prevent="search = 'Cancelado'"><i class="fas fa-filter mr-2"></i>Cancelado</a>
                        </div><!-- /dropdown-menu -->
                    </div><!-- /btn-group -->
                </div><!-- /btn-group -->
                <div class="mt-3 mb-3">
                    <div v-show="loading == true" class="alert alert-warning" role="alert">
                        <div class="alert-heading text-center mb-0">
                            <div class="spinner-border mr-2 align-middle" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <span>Carregando, aguarde...</span>
                        </div>
                    </div><!-- /alert-warning -->
                    <div v-show="error.length > 0" class="alert alert-danger" role="alert">
                        <h4 class="alert-heading">Ops, ocorreu um problema!</h4>
                        <hr />
                        <p class="mb-0">{{error}}</p>
                    </div><!-- /alert-danger -->
                    <div v-show="events.length == 0 && nothing_found == true" class="alert alert-info" role="alert">
                        <h4 class="alert-heading">Ops, ocorreu um problema!</h4>
                        <hr />
                        <p class="mb-0">Nenhum evento encontrado, tente novamente!</p>
                        <hr />
                        <div class="form-group mb-0">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <div class="input-group-text">Nova busca</div>
                                </div>
                                <input class="form-control" type="date" v-model="newdate" @change="searchDayEvents">
                            </div>
                        </div>
                    </div><!-- /alert-danger -->
                    <div v-show="events.length > 0 && nothing_found == false">
                        <div class="row ml-0 mb-3 mr-0">
                            <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                                <select class="form-control" :class="{'mb-3': platform == 'mobile'}" @change="searchLeague">
                                    <option value="" selected>Selecione uma liga</option>
                                    <option v-for="(item, index) in leaguesOrganizes(events)" :key="index" :value="item.liga">
                                        {{item.liga}}
                                    </option>
                                </select>
                            </div><!-- /col -->
                            <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                                <input class="form-control" type="text" v-model="search" :class="{'mb-3': platform == 'mobile'}" placeholder="Buscar time ou liga...">
                            </div><!-- /col -->
                            <div class="col pl-0 pr-0" :class="{'d-contents': platform == 'mobile'}">
                                <input class="form-control" type="date" id="date" @change="searchDayEvents">
                            </div><!-- /col -->
                        </div><!-- /row -->
                        <div class="row row-head w-100">
                            <div class="col text-truncate">
                                <i class="fas fa-calendar-alt mr-2"></i>
                                <span v-show="platform == 'desktop'">Horário</span>
                            </div><!-- /col -->
                            <div class="col text-truncate">
                                <i class="fas fa-long-arrow-alt-down mr-2"></i>
                                <span v-show="platform == 'desktop'">Mandante</span>
                            </div><!-- /col -->
                            <div class="col text-truncate">
                                <i class="fas fa-long-arrow-alt-up mr-2"></i>
                                <span v-show="platform == 'desktop'">Visitante</span>
                            </div><!-- /col -->
                            <div class="col text-truncate">
                                <i class="fas fa-cogs mr-2"></i>
                                <span v-show="platform == 'desktop'">Opções</span>
                            </div><!-- /col -->
                        </div><!-- /row -->
                        <template v-if="filteredListEvents.length == 0">
                            <div class="alert alert-info mt-3" role="alert">
                                <h4 class="alert-heading">Ops, ocorreu um problema!</h4>
                                <hr />
                                <p class="mb-0">Nenhum evento encontrado, tente novamente!</p>
                            </div><!-- /alert-danger -->                            
                        </template>
                        <template v-else>
                            <div class="row row-item w-100" v-for="(item, index) in filteredListEvents" :key="index">
                                <template v-if="index == 0">
                                    <div class="row row-league w-100">
                                        <div class="col-sm-12 text-dark text-truncate font-weight-bold">{{item.liga}}</div>
                                    </div>
                                </template>
                                <template v-else-if="item['liga_id'] !== filteredListEvents[index - 1]['liga_id']">
                                    <div class="row row-league w-100">
                                        <div class="col-sm-12 text-dark text-truncate font-weight-bold ">{{item.liga}}</div>
                                    </div>
                                </template>
                                <div class="row row-event w-100" :class="{'d-block': platform == 'mobile', 'row-lock': item.bloqueado == 1}">
                                    <div class="col text-truncate" :class="{'text-danger': platform == 'mobile'}">
                                        <span>{{item.horario | formatTimeTable}}</span>
                                        <hr v-if="platform == 'mobile'" />
                                    </div>
                                    <div class="col text-truncate">{{item.time_casa}}</div>
                                    <div class="col text-truncate">{{item.time_fora}}</div>
                                    <div class="col" :class="{'mt-3': platform == 'mobile'}">
                                        <button :class="{'btn-block': platform == 'mobile'}" :id="`btnGroupDropMenuID-${item._id}`" type="button" class="btn btn-secondary btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i class="fas fa-ellipsis-v mr-2"></i>Mais
                                        </button>
                                        <div class="dropdown-menu" :aria-labelledby="`btnGroupDropMenuID-${item._id}`">
                                            <div class="dropdown-header text-center text-uppercase">{{findStatus(item.status)}}</div>
                                            <div class="dropdown-divider"></div>
                                            <a class="dropdown-item" href="javascript:void(0);" @click="viewResult(item._id)"><i class="fas fa-poll-h mr-2"></i>Resultados</a>
                                            <a class="dropdown-item" href="javascript:void(0);" @click="viewStatistics(item._id)" v-if="item.esporte == '1'"><i class="fad fa-chart-bar mr-2"></i>Estatísticas</a>
                                            <template v-if="item.status == 0 || item.status == 1">
                                                <div class="dropdown-divider"></div>
                                                <a class="dropdown-item" href="javascript:void(0);" @click="cancelEvent(item)"><i class="fas fa-ban mr-2"></i>Cancelar</a>
                                            </template>
                                        </div><!-- /dropdown-menu -->
                                    </div><!-- /col -->
                                </div><!-- /row -->
                            </div><!-- /row -->
                        </template>
                    </div><!-- /div -->
                </div><!-- /mb-3 -->
            </div><!-- /container -->
        </div><!-- /div -->
      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></loading>
    </main><!-- /main -->
</template>

<script>
import {api} from '../api'
import Navbar from './Navbar'
import Swal from 'sweetalert2'
import moment from 'moment-timezone'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

const today = moment().tz('America/Recife').format('YYYY-MM-DD');

export default {
    data() {
        return {
            sport: 0,
            error: '',
            search: '',
            events: [],
            newdate: today,
            loading: false,
            isLoading: false,
            nothing_found: false,
            screen_w: window.screen.width,
            screen_h: window.screen.height,
            platform: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop',
            network_erros: {
				0: {
					message: 'Não há conexão com a internet!'
                },
                401: {
                    message: 'Você não tem autorização para realizar essa operação!'
                },
				404: {
					message: 'Página solicitada não foi encontrada!'
				},
				500: {
					message: 'O servidor está passando por problemas técnicos!'
				},
				408: {
					message: 'Por favor, verifique sua conexão de rede e tente novamente!'
				}					
			}
        }
    },
    components: {
        Navbar,
        Loading
    },
    methods: {
        viewResult(id) {

            const self = this;
            const index = self.events.findIndex((obj => obj._id == id));

            try {

                const event = self.events[index];

                Swal.fire(
                    'Resultados',
                    `
                    <div class="row bg-warning mb-2 pt-2 pb-2 rounded">
                        <div class="col text-center text-truncate">${event.time_casa}</div>
                        <div class="col-auto text-center">
                            <i class="fal fa-times"></i>
                        </div>
                        <div class="col text-center text-truncate">${event.time_fora}</div>
                    </div>
                    <div class="row pt-2 pb-2">
                        <div class="col text-center text-info">${this.findStatus(event.status)}</div>
                    </div>
                    <div class="row pt-2 pb-2 border-bottom">
                        <div class="col text-truncate text-left">${event.esporte == '1' ? '90 minutos' : 'Resultado'}</div>
                        <div class="col-auto text-center">
                            <i class="fal fa-arrows-h"></i>
                        </div>
                        <div class="col text-truncate text-right">${event.resultados.ft.casa}-${event.resultados.ft.fora}</div>
                    </div>
                    ${event.esporte == '1' ? `
                        <div class="row pt-2 pb-2 border-bottom">
                            <div class="col text-truncate text-left">Primeiro tempo</div>
                            <div class="col-auto text-center">
                                <i class="fal fa-arrows-h"></i>
                            </div>
                            <div class="col text-truncate text-right">${event.resultados.pt.casa}-${event.resultados.pt.fora}</div>
                        </div>
                        <div class="row pt-2 pb-2 border-bottom">
                            <div class="col text-truncate text-left">Segundo tempo</div>
                            <div class="col-auto text-center">
                                <i class="fal fa-arrows-h"></i>
                            </div>
                            <div class="col text-truncate text-right">${event.resultados.st.casa}-${event.resultados.st.fora}</div>
                        </div>
                        <div class="row pt-2">
                            <div class="col text-center">
                                <small>As informações fornecidas acima são totalmente responsabilidade da nossa provedora <strong class="text-info text-uppercase">${event.servidor}</strong></small>
                            </div>
                        </div>                
                    `: ''}
                    `
                );
            } catch(e) {
                Swal.fire(
                    'Atenção!',
                    'Nenhuma informação disponível para esse evento!',
                    'warning'
                );
            }
        },
        viewStatistics(id) {

            const self = this;
            const index = self.events.findIndex((obj => obj._id == id));

            try {

                let html = '';
                const event = self.events[index];

                event.estatisticas.jogadores.forEach(item => {
                    html += `
                    <div class="row pt-2 pb-2 border-bottom">
                        <div class="col text-truncate text-left">${item.jogador}</div>
                        <div class="col-auto text-center">
                            <i class="fal fa-arrows-h"></i>
                        </div>
                        <div class="col text-truncate text-right">${item.momento} - <span class="text-uppercase">(${item.time.slice(0,3)})</span></div>
                    </div>
                    `;
                });

                Swal.fire(
                    'Estatísticas',
                    `
                    <div class="row bg-warning mb-2 pt-2 pb-2 rounded">
                        <div class="col text-center text-truncate">${event.time_casa}</div>
                        <div class="col-auto text-center">
                            <i class="fal fa-times"></i>
                        </div>
                        <div class="col text-center text-truncate">${event.time_fora}</div>
                    </div>
                    <nav>
                        <div class="nav nav-tabs nav-justified" id="nav-tab" role="tablist">
                            <a class="nav-item nav-link active" id="nav-card-tab" data-toggle="tab" href="#nav-card" role="tab" aria-controls="nav-card" aria-selected="true">Cartões</a>
                            <a class="nav-item nav-link" id="nav-corners-tab" data-toggle="tab" href="#nav-corners" role="tab" aria-controls="nav-corners" aria-selected="false">Escanteios</a>
                            <a class="nav-item nav-link" id="nav-players-tab" data-toggle="tab" href="#nav-players" role="tab" aria-controls="nav-players" aria-selected="false">Jogadores</a>
                        </div>
                    </nav>
                    <div class="tab-content" id="nav-tabContent">
                        <div class="tab-pane fade show active" id="nav-card" role="tabpanel" aria-labelledby="nav-card-tab">
                            <div class="row pt-2 pb-2 border-bottom">
                                <div class="col text-truncate text-left">Total</div>
                                <div class="col-auto text-center">
                                    <i class="fal fa-arrows-h"></i>
                                </div>
                                <div class="col text-truncate text-right">${event.estatisticas.cartoes.total}</div>
                            </div>
                            <div class="row pt-2 pb-2 border-bottom">
                                <div class="col text-truncate text-left">Total casa</div>
                                <div class="col-auto text-center">
                                    <i class="fal fa-arrows-h"></i>
                                </div>
                                <div class="col text-truncate text-right">${event.estatisticas.cartoes.total_casa}</div>
                            </div>
                            <div class="row pt-2 pb-2 border-bottom">
                                <div class="col text-truncate text-left">Total fora</div>
                                <div class="col-auto text-center">
                                    <i class="fal fa-arrows-h"></i>
                                </div>
                                <div class="col text-truncate text-right">${event.estatisticas.cartoes.total_fora}</div>
                            </div>
                            <div class="row pt-2">
                                <div class="col text-center">
                                    <small>As informações fornecidas acima são totalmente responsabilidade da nossa provedora <strong class="text-info text-uppercase">${event.servidor}</strong></small>
                                </div>
                            </div>     
                        </div>
                        <div class="tab-pane fade" id="nav-corners" role="tabpanel" aria-labelledby="nav-corners-tab">
                            <div class="row pt-2 pb-2 border-bottom">
                                <div class="col text-truncate text-left">Total</div>
                                <div class="col-auto text-center">
                                    <i class="fal fa-arrows-h"></i>
                                </div>
                                <div class="col text-truncate text-right">${event.estatisticas.escanteios.total}</div>
                            </div>
                            <div class="row pt-2 pb-2 border-bottom">
                                <div class="col text-truncate text-left">Total casa</div>
                                <div class="col-auto text-center">
                                    <i class="fal fa-arrows-h"></i>
                                </div>
                                <div class="col text-truncate text-right">${event.estatisticas.escanteios.total_casa}</div>
                            </div>
                            <div class="row pt-2 pb-2 border-bottom">
                                <div class="col text-truncate text-left">Total fora</div>
                                <div class="col-auto text-center">
                                    <i class="fal fa-arrows-h"></i>
                                </div>
                                <div class="col text-truncate text-right">${event.estatisticas.escanteios.total_fora}</div>
                            </div>
                            <div class="row pt-2">
                                <div class="col text-center">
                                    <small>As informações fornecidas acima são totalmente responsabilidade da nossa provedora <strong class="text-info text-uppercase">${event.servidor}</strong></small>
                                </div>
                            </div>                    
                        </div>
                        <div class="tab-pane fade" id="nav-players" role="tabpanel" aria-labelledby="nav-players-tab">
                            ${html == '' ? '<div class="mt-3">Nenhuma informação disponível</div>' : html}
                            <div class="row pt-2">
                                <div class="col text-center">
                                    <small>As informações fornecidas acima são totalmente responsabilidade da nossa provedora <strong class="text-info text-uppercase">${event.servidor}</strong></small>
                                </div>
                            </div>  
                        </div>
                    </div>
                    `
                );
            } catch(e) {
                Swal.fire(
                    'Atenção!',
                    'Nenhuma informação disponível para esse evento!',
                    'warning'
                );
            }
        },
        selectSport(sport_id) {

            const self = this;

            self.error = '',
            self.search = '',
            self.events = [];
            self.loading = true;
            self.sport = sport_id;
            self.nothing_found = false;

            document.getElementById('date').value = today;

            api.get(`dashboard/events/${today}/sport/${sport_id}/results`).then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.events = response.data.events.sort((a, b) => {

                            if (self.removeAccents(a.liga) < self.removeAccents(b.liga)) return -1;
                            if (self.removeAccents(a.liga) > self.removeAccents(b.liga)) return 1;

                            if (a.horario < b.horario) return -1;
                            if (a.horario > b.horario) return 1;

                            return 0;
                        });
                    break;
                    case 'nothing_found':
                        self.nothing_found = true;
                    break;
                    default:
                        self.error = response.data.message;
                }
            }).catch((error) => {
                try {
                    self.error = self.network_erros[error.status]['message'];
                } catch(e) {
                    self.error = self.network_erros[408]['message'];
                }
            }).finally(() => {
                self.loading = false;
            });
        },
        leaguesOrganizes(arr) {
            return arr.reduce((unique, o) => {
                if(!unique.some(obj => obj.liga_id === o.liga_id)) {
                    unique.push(o);
                }
                return unique;
            },[]).sort((a, b) => {
                return this.removeAccents(a.liga).localeCompare(this.removeAccents(b.liga))
            });
        },
        searchLeague(event) {
           this.search = event.target.value;
        }, 
        searchDayEvents(event) {

            const self = this;

            self.error = '',
            self.search = '',
            self.events = [];
            self.loading = true;
            self.nothing_found = false;
            self.sport = self.sport == 0 ? 1 : self.sport;
            
            api.get(`dashboard/events/${event.target.value}/sport/${self.sport}/results`).then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.events = response.data.events.sort((a, b) => {

                            if (self.removeAccents(a.liga) < self.removeAccents(b.liga)) return -1;
                            if (self.removeAccents(a.liga) > self.removeAccents(b.liga)) return 1;

                            if (a.horario < b.horario) return -1;
                            if (a.horario > b.horario) return 1;

                            return 0;
                        });
                    break;
                    case 'nothing_found':
                        //self.sport = 0;
                        self.nothing_found = true;
                    break;
                    default:
                        self.error = response.data.message;
                }
            }).catch((error) => {
                try {
                    self.error = self.network_erros[error.status]['message'];
                } catch(e) {
                    self.error = self.network_erros[408]['message'];
                }
            }).finally(() => {
                self.loading = false;
            });
        },
        cancelEvent(item) {
            
            const self = this;
            let timerInterval;

            Swal.fire({
                title: 'Confirmação',
                html: `Você deseja mesmo cancelar o evento: <br /> <b>${item.time_casa}</b> x <b>${item.time_fora}</b></p> <hr > Lembrando que caso confirme, todas as apostas que contenha esse evento, será <b class="text-danger">recorrigida</b> e o jogo será <b class="text-danger">cancelado</b> da aposta.`,
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Sim'
            }).then((result) => {

                if (result.value) {

                    self.isLoading = true;

                    api.put(`dashboard/cancel-event/${item._id}`).then((response) => {
                        switch(response.data.result) {
                            case 'success':
                                Swal.fire({
                                    title: `Aguarde...`,
                                    html: `
                                        <p class="m-0">Iniciando o processo de correção!</p>
                                        <p>Aguarde <b></b> o processo vai iniciar.</p>
                                    `,
                                    timer: 10000,
                                    timerProgressBar: true,
                                    allowOutsideClick: false,
                                    allowEscapeKey: false,
                                    allowEnterKey: false,
                                    showConfirmButton: false,
                                    showCancelButton: false,
                                    onOpen: () => {
                                        Swal.showLoading()
                                        const b = Swal.getHtmlContainer().querySelector('b')
                                        timerInterval = setInterval(() => {
                                            b.textContent = self.toSecond(Swal.getTimerLeft())
                                        }, 100)
                                    },
                                    onClose: () => {
                                        clearInterval(timerInterval)
                                    }
                                }).then((result) => {
                                    if (result.dismiss === Swal.DismissReason.timer) {
                                        self.bettingCorrection(item._id);
                                    }
                                });
                            break;
                            default:
                                Swal.fire(
                                    'Atenção!',
                                    response.data.message,
                                    'warning'
                                );
                            break;
                        }
                    }).catch((error) => {
                        try {
                            Swal.fire(
                                'Falha',
                                self.network_erros[error.status]['message'],
                                'error'
                            );
                        } catch(e) {
                            Swal.fire(
                                'Falha',
                                self.network_erros[408]['message'],
                                'error'
                            );
                        }
                    }).finally(() => {
                        self.isLoading = false;
                    }); 
                }
            });
        },
        bettingCorrection(id) {

            Swal.fire({
                title: 'Corrigindo as apostas',
                html: 'Aguarde... <br /> Esse processo pode demorar um pouco. <hr /> <b class="text-danger">Não saia da página e nem atualize.</b>',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: false,
                showCancelButton: false,
                onBeforeOpen: () => {
                    Swal.showLoading()
                }
            });

            api.get(`betting-correction/${id}`).then((response) => {
                switch(response.data.result) {
                    case 'success':
                        Swal.fire(
                            'Parabéns!',
                            response.data.message.replace(/\n/g, "<br />"),
                            'success'
                        );
                    break;
                    default:
                        Swal.fire(
                            'Atenção!',
                            response.data.message,
                            'warning'
                        );
                    break;
                }
            }).catch((error) => {
                try {
                    Swal.fire(
                        'Falha',
                        this.network_erros[error.status]['message'],
                        'error'
                    );
                } catch(e) {
                    Swal.fire(
                        'Falha',
                        this.network_erros[408]['message'],
                        'error'
                    );
                }
            });
        },
        toSecond(ms) {
            return Math.floor((ms/1000) % 60);
        },
        isArray(obj) {
            return Array.isArray(obj);
        },
        removeAccents(str) {
            
            let string = str;
            let map = {a : /[\xE0-\xE6]/g, A : /[\xC0-\xC6]/g, e : /[\xE8-\xEB]/g, E : /[\xC8-\xCB]/g, i : /[\xEC-\xEF]/g, I : /[\xCC-\xCF]/g, o : /[\xF2-\xF6]/g, O : /[\xD2-\xD6]/g, u : /[\xF9-\xFC]/g, U : /[\xD9-\xDC]/g, c : /\xE7/g, C : /\xC7/g, n : /\xF1/g, N : /\xD1/g};

            for (let key in map) {
                 let rex = map[key];
                 string = string.replace(rex, key);
            }

            return string;
        }
    },
    watch: {
        sport() {
            this.newdate = today;
        },
        search(value) {
            if (value.trim().length == 0) this.search = '';
        }
    },
    filters: {
        formatTimeTable(time) {
            return moment(time).tz('America/Recife').format("DD/MM HH:mm");
        }
    },
    computed: {
        filteredListEvents() {
            return this.events.filter((item) => {
                return item.liga.toLowerCase().trim().includes(this.search.toLowerCase().trim()) || item.time_casa.toLowerCase().trim().includes(this.search.toLowerCase().trim()) || item.time_fora.toLowerCase().trim().includes(this.search.toLowerCase().trim()) || item.situacao.toLowerCase().trim().includes(this.search.toLowerCase().trim())
            });
        }
    },
    created() {
        window.addEventListener('resize', () => {
            this.platform = this.isMobile();
        });
    }
}
</script>

<style scoped>
.main {
    margin-top: 80px;
}
.dropdown-toggle:after {
  border: none;
}
.col-sm {
    background-color: #ddd
}
.btn .badge {
    top: 3px!important;
}
.mt-3.mb-3 .row {
    margin-left: 0;
    margin-right: 0;
}
.row-head {
    padding: .75rem;
    background-color: #212529;
    color: #fff;
    vertical-align: bottom;
    text-align: center;
}
.row-league {
    padding: .75rem;
    background-color: #ffc107;
    color: #fff;
    vertical-align: bottom;
    text-align: center;
}
.row-event {
    padding: .75rem;
    color: #fff;
    vertical-align: bottom;
    text-align: center;
}
.row-event .col.text-truncate {
    position: relative;
    z-index: 0;
    top: 3px;
}
.row-event .col.text-truncate hr {
    border-top-color: #666;
}
.row.row-item {
    background-color: #212529;
}
.row.row-item:nth-of-type(odd) {
    background-color: rgba(255,255,255,.05);
}
.row-event.row-lock {
    background-color: rgba(255, 235, 59, 0.2784313725490196);
}
.d-contents {
    display: contents!important;
}
input[type=date]::-webkit-clear-button {
    display: none; /* Hide the button */
    -webkit-appearance: none; /* turn off default browser styling */
}
input[type=date]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    display: none;
}
.modal-edit-scroll-area {
    width: 100%;
    padding-bottom: 10px;
    overflow: hidden auto;
    height: calc(100% - 56px);
}
.modal-edit-scroll-area::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background: #4b4b4b;
    border-radius: 0;
}
.modal-edit-scroll-area::-webkit-scrollbar-thumb {
    background: #373737;
    border-radius: 0;
}
.modal-edit-close {
    color: #fff;
}
.modal-edit-close:hover {
    color: #ddd;
}
.drop-left {
    margin-left: -100%!important;
}
</style>