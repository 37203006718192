<template>
    <main>
        <Navbar />
        <div class="main">
            <div class="container">
                <div class="mt-3 mb-3">
                    <div v-show="loading == true" class="alert alert-warning" role="alert">
                        <div class="alert-heading text-center mb-0">
                            <div class="spinner-border mr-2 align-middle" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <span>Carregando, aguarde...</span>
                        </div>
                    </div><!-- /alert-warning -->
                    <div v-show="error.show" class="alert alert-danger" role="alert">
                        <h4 class="alert-heading">Ops, ocorreu um problema!</h4>
                        <hr />
                        <p class="mb-0">{{error.message}}</p>
                    </div><!-- /alert-danger -->
                    <div v-show="events.length && !error.show">
                        <div class="row ml-0 mb-3 mr-0">
                            <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                                <select class="form-control" :class="{'mb-3': platform == 'mobile'}" @change="searchLeague">
                                    <option value="" selected>Selecione uma liga</option>
                                    <option v-for="(item, index) in leaguesOrganizes(events)" :key="index" :value="item.liga">
                                        {{item.liga}}
                                    </option>
                                </select>
                            </div><!-- /col -->
                            <div class="col pl-0 pr-0" :class="{'d-contents': platform == 'mobile'}">
                                <input class="form-control" type="text" v-model="search" placeholder="Buscar time ou liga...">
                            </div><!-- /col -->
                        </div><!-- /row -->
                        <div class="row row-head w-100">
                            <div class="col text-truncate">
                                <i class="fas fa-info-circle mr-2"></i>
                                <span v-show="platform == 'desktop'">Tempo/Placar</span>
                            </div><!-- /col -->
                            <div class="col text-truncate">
                                <i class="fas fa-long-arrow-alt-down mr-2"></i>
                                <span v-show="platform == 'desktop'">Mandante</span>
                            </div><!-- /col -->
                            <div class="col text-truncate">
                                <i class="fas fa-long-arrow-alt-up mr-2"></i>
                                <span v-show="platform == 'desktop'">Visitante</span>
                            </div><!-- /col -->
                            <div class="col text-truncate">
                                <i class="fas fa-cogs mr-2"></i>
                                <span v-show="platform == 'desktop'">Opções</span>
                            </div><!-- /col -->
                        </div><!-- /row -->
                        <template v-if="filteredListEvents.length == 0">
                            <div class="alert alert-info mt-3" role="alert">
                                <h4 class="alert-heading">Ops, ocorreu um problema!</h4>
                                <hr />
                                <p class="mb-0">Nenhum evento encontrado, tente novamente!</p>
                            </div><!-- /alert-danger -->                            
                        </template>
                        <template v-else>
                            <div class="row row-item w-100" v-for="(item, index) in orderByLeague(filteredListEvents)" :key="index">
                                <template v-if="index == 0">
                                    <div class="row row-league w-100">
                                        <div class="col-sm-12 text-dark text-truncate font-weight-bold">{{item.liga}}</div>
                                    </div>
                                </template>
                                <template v-else-if="item['liga_id'] !== filteredListEvents[index - 1]['liga_id']">
                                    <div class="row row-league w-100">
                                        <div class="col-sm-12 text-dark text-truncate font-weight-bold ">{{item.liga}}</div>
                                    </div>
                                </template>
                                <div class="row row-event w-100" :class="{'d-block': platform == 'mobile', 'row-lock': item.bloqueado == 1}">
                                    <div class="col text-truncate" :class="{'text-danger': platform == 'mobile'}">
                                        <span>{{item.tempo}} / {{item.placar}}</span>
                                        <hr v-if="platform == 'mobile'" />
                                    </div>
                                    <div class="col text-truncate">{{item.time_casa}}</div>
                                    <div class="col text-truncate">{{item.time_fora}}</div>
                                    <div class="col" :class="{'mt-3': platform == 'mobile'}">
                                        <button :class="{'btn-block': platform == 'mobile'}" :id="`btnGroupDropMenuID-${item.id}`" type="button" class="btn btn-secondary btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i class="fas fa-ellipsis-v mr-2"></i>Mais
                                        </button>
                                        <div class="dropdown-menu" :aria-labelledby="`btnGroupDropMenuID-${item.id}`">
                                            <a class="dropdown-item" href="javascript:void(0);" @click="showOdds(item)"><i class="fas fa-usd-square mr-2"></i>Odds</a>
                                            <a class="dropdown-item" href="javascript:void(0);" @click="locksAndUnlocks(item.id, item.bloqueado)">
                                                <template v-if="item.bloqueado == 2">
                                                    <i class="fas fa-lock mr-2"></i>Bloquear
                                                </template>
                                                <template v-else>
                                                    <i class="fas fa-unlock mr-2"></i>Desbloquear
                                                </template>
                                            </a>
                                        </div><!-- /dropdown-menu -->
                                    </div><!-- /col -->
                                </div><!-- /row -->
                            </div><!-- /row -->
                        </template>
                    </div><!-- /div -->
                </div>
            </div>
        </div>
        <modal name="edit-odds" :width="platform == 'desktop' ? 600 : screen_w" :height="platform == 'desktop' ? 500 : screen_h" :adaptive="true" :classes="['modal-dark','modal-edit-dark']">
            <template v-if="Object.keys(modal).length">
                <header class="modal-edit-header">
                    <div class="row">
                        <div class="col-8 text-light text-uppercase mt-1">Odds ao vivo</div>
                        <div class="col-4 text-right">
                            <div class="d-flex align-items-center justify-content-end flex-wrap">
                                <div class="modal-edit-close ml-3" role="button" @click="$modal.hide('edit-odds')">
                                    <i class="fal fa-times"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div class="modal-edit-scroll-area">
                    <div class="container">
                        <div class="row bg-dark">
                            <div class="col text-center text-truncate text-white">
                                <div>
                                    <img :src="`${image_server}/s/${imageShield(modal.partida.time_casa.nome)}`" @error="imageError($event, '/images/shield_default.png')" @load="imageCheck" :alt="modal.partida.time_casa.nome" border="0" width="48" height="48">
                                </div>
                                <div class="text-truncate">{{modal.partida.time_casa.nome}}</div>
                            </div>
                            <div class="col-auto text-center text-white">
                                <div class="d-flex h-100 flex-column justify-content-center align-items-center">
                                    <i class="fal fa-times"></i>
                                </div>
                            </div>
                            <div class="col text-center text-truncate text-white">
                                <div>
                                    <img :src="`${image_server}/s/${imageShield(modal.partida.time_fora.nome)}`" @error="imageError($event, '/images/shield_default.png')" @load="imageCheck" :alt="modal.partida.time_fora.nome" border="0" width="48" height="48">
                                </div>
                                <div class="text-truncate">{{modal.partida.time_fora.nome}}</div>
                            </div>
                        </div>
                        <div class="row p-0" v-for="(item, index) in modal.odds" :key="index">
                            <template v-if="isArray(item.odd)">
                                <template v-if="item.odd.length">
                                    <div class="col-12 p-0">
                                        <div class="row bg-warning p-2">
                                            <div class="col-12 mt-2 pb-2 text-dark text-uppercase text-truncate"><span class="badge badge-danger mr-2 align-text-bottom">{{item.cat_id}}</span>{{item.option}}</div>
                                        </div>
                                    </div>
                                    <div class="col-12 p-0" v-for="(option, key) in item.odd" :key="key">
                                        <!-- /OPÇÂO PLACAR -->
                                        <template v-if="item.id == 27 || item.id == 155">
                                            <div class="row rw">
                                                <div class="col-8 mt-2 text-white text-truncate">
                                                    <template v-if="option.score_home > option.score_away">
                                                        Casa ({{option.score.replace('-',':')}})
                                                    </template>
                                                    <template v-if="option.score_home == option.score_away">
                                                        Empate ({{option.score.replace('-',':')}})
                                                    </template>
                                                    <template v-else-if="option.score_home < option.score_away">
                                                        Fora ({{option.score.replace('-',':')}})
                                                    </template>
                                                </div>
                                                <div class="col-4">
                                                    <div class="d-flex flex-column">
                                                        <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text">$</div>
                                                        </div>
                                                            <input type="number" class="form-control" :value="modal.odds[index].odd[key].odd" autocomplete="off" spellcheck="false" readonly>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- /OPÇÂO DE JOGADOR -->
                                        <template v-else-if="item.id == 98 || item.id == 99">
                                            <div class="row rw">
                                                <div class="col-8 mt-2 text-white text-truncate">{{option.player}}</div>
                                                <div class="col-4">
                                                    <div class="d-flex flex-column">
                                                        <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text">$</div>
                                                        </div>
                                                            <input type="number" class="form-control" :value="modal.odds[index].odd[key].odd" autocomplete="off" spellcheck="false" readonly>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- /OPÇÂO DE ESCANTEIOS MAIS/MENOS/EXATOS -->
                                        <template v-else-if="item.id == 100 || item.id == 101 || item.id == 102">
                                            <div class="row rw">
                                                <div class="col-8 mt-2 text-white text-truncate">{{item.id == 100 ? 'Mais de ' : item.id == 101 ? 'Menos de ' : 'Exatos '}} {{option.handicap}}</div>
                                                <div class="col-4">
                                                    <div class="d-flex flex-column">
                                                        <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text">$</div>
                                                        </div>
                                                            <input type="number" class="form-control" :value="modal.odds[index].odd[key].odd" autocomplete="off" spellcheck="false" readonly>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- /OPÇÂO DE CARTÕES PARTIDA/CASA/FORA -->
                                        <template v-else-if="item.id == 123 || item.id == 124 || item.id == 125 || item.id == 126 || item.id == 127 || item.id == 128">
                                            <div class="row rw">
                                                <div class="col-8 mt-2 text-white text-truncate">{{item.id == 123 || item.id == 125 || item.id == 127 ? 'Acima de ' : 'Abaixo de '}} {{option.handicap}}</div>
                                                <div class="col-4">
                                                    <div class="d-flex flex-column">
                                                        <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text">$</div>
                                                        </div>
                                                            <input type="number" class="form-control" :value="modal.odds[index].odd[key].odd" autocomplete="off" spellcheck="false" readonly>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- /OPÇÂO DE TOTAL DE PONTOS PARTIDA/CASA/FORA -->
                                        <template v-else-if="item.id == 235 || item.id == 236 || item.id == 237 || item.id == 238 || item.id == 239 || item.id == 240">
                                            <div class="row rw">
                                                <div class="col-8 mt-2 text-white text-truncate">{{item.id == 235 || item.id == 237 || item.id == 239 ? 'Mais de ' : 'Menos de '}} {{option.handicap}}</div>
                                                <div class="col-4">
                                                    <div class="d-flex flex-column">
                                                        <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text">$</div>
                                                        </div>
                                                            <input type="number" class="form-control" :value="modal.odds[index].odd[key].odd" autocomplete="off" spellcheck="false" readonly>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </template>
                            </template>
                            <template v-else>
                                <template v-if="index == 0">
                                    <div class="col-12 p-0">
                                        <div class="row bg-warning p-2">
                                            <div class="col-12 mt-2 pb-2 text-dark text-uppercase text-truncate"><span class="badge badge-danger mr-2 align-text-bottom">{{item.cat_id}}</span>{{item.cat_name}}</div>
                                        </div>
                                    </div>
                                 </template>
                                 <template v-else-if="item.cat_name != modal.odds[index - 1].cat_name">
                                    <div class="col-12 p-0">
                                        <div class="row bg-warning p-2">
                                            <div class="col-12 mt-2 pb-2 text-dark text-uppercase text-truncate"><span class="badge badge-danger mr-2 align-text-bottom">{{item.cat_id}}</span>{{item.cat_name}}</div>
                                        </div>
                                    </div>
                                 </template>
                                 <div class="w-100">
                                     <div class="row">
                                        <div class="col-8 mt-2 text-white text-truncate">{{modal.odds[index].option}}</div>
                                        <div class="col-4">
                                            <div class="d-flex flex-column">
                                                <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">$</div>
                                                </div>
                                                    <input type="number" class="form-control" :value="modal.odds[index].odd" autocomplete="off" spellcheck="false" readonly>
                                                </div>
                                            </div>
                                        </div>
                                     </div>
                                 </div>
                            </template>
                        </div>
                    </div>
                </div>
            </template>
        </modal>
    </main>
</template>

<script>
import Vue from 'vue'
import {api} from '../api'
import Navbar from './Navbar'
import Swal from 'sweetalert2'
import io from 'socket.io-client'
import VModal from 'vue-js-modal'

Vue.use(VModal)

let socket = io('wss://live-stream.betsnow.net', {
    secure: true,
    autoConnect: false,
    rememberUpgrade: true,
    rejectUnauthorized: false,
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: Infinity,
    transports: ['websocket']
});

export default {
    data() {
        return {
            error: {
                show: false,
                message: ''
            },
            modal: {},
            search: '',
            events: [],
            eventsDB: {},
            loading: false,
            screen_w: window.screen.width,
            screen_h: window.screen.height,
            platform: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop',
            network_erros: {
				0: {
					message: 'Não há conexão com a internet!'
                },
                401: {
                    message: 'Você não tem autorização para realizar essa operação!'
                },
				404: {
					message: 'Página solicitada não foi encontrada!'
				},
				500: {
					message: 'O servidor está passando por problemas técnicos!'
				},
				408: {
					message: 'Por favor, verifique sua conexão de rede e tente novamente!'
				}					
			}
        }
    },
    methods: {
        getLiveEvents() {

            const self = this;

            self.loading = true;
            self.error.show = false;
            self.error.message = '';

            api.get('live').then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.inplay(response.data.events);
                    break;
                    case 'nothing_found':
                        self.loading = false;
                        self.error.show = true;
                        self.error.message = 'Nenhum evento disponível!';
                    break;
                    default:
                        self.loading = false;
                        self.error.show = true;
                        self.error.message = response.data.message;
                    break;
                }
            }).catch((error) => {
                try {
                    self.loading = false;
                    self.error.show = true;
                    self.error.message = self.network_erros[error.status]['message'];                        
                } catch(e) {
                    self.loading = false;
                    self.error.show = true;
                    self.error.message = self.network_erros[408]['message'];   
                }
            });
        },
        inplay(eventos) {

            const self = this;

            socket.open();

            socket.on('connect_failed', () => {
                self.error.show = true;
                self.error.message = 'Não foi possível conectar ao servidor!';
            });

            socket.on('connect_error', () => {
                self.error.show = true;
                self.error.message = 'Ocorreu um erro na conexão com o servidor!';
            });

            socket.on('events', (snapshot) => {

                try {

                    const partidas = [];
                    const {result, total, events} = JSON.parse(snapshot);

                    if (result == 'nothing_found' || total == 0) {
                        self.loading = false;
                        self.error.show = true;
                        self.error.message = 'Nenhum evento disponível!';
                    } else {
                        events.forEach((event) => {

                            let item = eventos.find((obj) => obj.api_id == event.id);

                            if (item instanceof Object) {

                                if (self.eventsDB[item.id] === undefined) {
                                    self.eventsDB[item.id] = {
                                        bloqueado: item.bloqueado
                                    };
                                }

                                partidas.push({
                                    id: item.id,
                                    liga: item.liga,
                                    liga_id: item.liga_id,
                                    time_casa: item.time_casa,
                                    time_fora: item.time_fora,
                                    horario: item.horario,
                                    tempo: event.timer,
                                    placar: event.score,
                                    periodo: event.period,
                                    cotacoes: event.odds.cotacoes,
                                    bloqueado: self.checkLock(item.id, item.bloqueado)
                                });
                            }
                        });

                        if (partidas.length == 0) {
                            self.loading = false;
                            self.error.show = true;
                            self.error.message = 'Nenhum evento disponível!';
                        } else {
                            self.loading = false;
                            self.error.show = false;
                            self.events = partidas;
                        }
                    }
                } 
                catch(e) 
                {
                    self.loading = false;
                    self.error.show = true;
                    self.error.message = (e.message || 'Ocorreu um erro inesperado, atualize a página!');
                } 
            });
        },
        showOdds(item) {
            this.modal = {
                partida: {
                    time_casa: {
                        nome: item.time_casa
                    },
                    time_fora: {
                        nome: item.time_fora
                    }
                },
                odds: item.cotacoes
            };
            this.$modal.show('edit-odds', {
                id: item.id
            });
        },
        locksAndUnlocks(id, value) {

            const self = this;
            const type = value == 2 ? 1 : 2;
            const label = type == 1 ? 'Bloquear' : 'Desbloquear';
            const index = self.events.findIndex((obj => obj.id == id));

            const event = self.events[index];

            Swal.fire({
				title: `${label} evento`,
				html: `Você deseja mesmo ${label.toLowerCase()} <p class="mb-0"><b>"${event.time_casa} X ${event.time_fora}"</b> ?</p>`,
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				cancelButtonText: 'Cancelar',
				confirmButtonText: 'Sim'
			}).then((result) => {

                if (result.value) {

                    Swal.fire({
                        showCancelButton: false,
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        text: 'Salvando alterações...',
                        onBeforeOpen: () => {
                            Swal.showLoading()  
                        }
                    });

                    api.put(`dashboard/lock-unlock-event/${id}/${type}`).then((response) => {
                        switch(response.data.result) {
                            case 'success':

                                if (typeof(self.eventsDB[id]) !== 'undefined') {
                                    event.bloqueado = type;
                                    self.eventsDB[id].bloqueado = type;
                                }

                                Swal.fire(
                                    'Parabéns!',
                                    `O evento foi ${type == 1 ? 'bloqueado' : 'debloqueado'} com sucesso!`,
                                    'success'
                                );
                            break;
                            default:
                                Swal.fire(
                                    'Falha',
                                    response.data.message,
                                    'error'
                                );
                        }
                    }).catch((error) => {
                        try {
                            Swal.fire(
                                'Falha',
                                self.network_erros[error.status]['message'],
                                'warning'
                            );
                        } catch(e) {
                            Swal.fire(
                                'Falha',
                                self.network_erros[408]['message'],
                                'warning'
                            );
                        }
                    });
                }
            });
        },
        isArray(obj) {
            return Array.isArray(obj);
        },
        checkLock(id, lock) {
            
            if (typeof(this.eventsDB[id]) == 'undefined') {
                return lock;
            } else {
                return this.eventsDB[id].bloqueado;
            }
        },
        orderByLeague(events) {
            return events.sort((a, b) => {
                return this.removeAccents(a.liga).localeCompare(this.removeAccents(b.liga))
            });
        },
        leaguesOrganizes(arr) {
            return arr.reduce((unique, o) => {
                if(!unique.some(obj => obj.liga_id === o.liga_id)) {
                    unique.push(o);
                }
                return unique;
            },[]).sort((a, b) => {
                return this.removeAccents(a.liga).localeCompare(this.removeAccents(b.liga))
            });
        },
        searchLeague(event) {
           this.search = event.target.value;
        }, 
        removeAccents(str) {
            
            let string = str;
            let map = {a : /[\xE0-\xE6]/g, A : /[\xC0-\xC6]/g, e : /[\xE8-\xEB]/g, E : /[\xC8-\xCB]/g, i : /[\xEC-\xEF]/g, I : /[\xCC-\xCF]/g, o : /[\xF2-\xF6]/g, O : /[\xD2-\xD6]/g, u : /[\xF9-\xFC]/g, U : /[\xD9-\xDC]/g, c : /\xE7/g, C : /\xC7/g, n : /\xF1/g, N : /\xD1/g};

            for (let key in map) {
                 let rex = map[key];
                 string = string.replace(rex, key);
            }

            return string;
        },
        imageError(event, image) {
            event.target.src = image;
        },
        imageShield(team) {
            try {
                return `${this.removeAccents(team).toLowerCase().trim().replace(/[^a-z-0-9\s]/g, '').replace(/\s/g, '-')}.png`;
            } catch(e) {
                return 'shield.png';
            }
        },
        imageCheck(event) {
            try {
                var w = event.target.naturalWidth;
                var h = event.target.naturalHeight;

                if (w == 1 && h == 1) {
                    event.target.src = 'shield.png';
                }
            } catch(e) {
                event.target.src = 'shield.png';
            }
        },
    },
    components: {
        Navbar
    },
    watch: {
        search(value) {
            if (value.trim().length == 0) this.search = '';
        }
    },
    computed: {
        filteredListEvents() {
            return this.events.filter((item) => {
                return item.liga.toLowerCase().trim().includes(this.search.toLowerCase().trim()) || item.time_casa.toLowerCase().trim().includes(this.search.toLowerCase().trim()) || item.time_fora.toLowerCase().trim().includes(this.search.toLowerCase().trim())
            });
        }
    },
    mounted() {
        window.addEventListener('resize', () => {
            this.platform = this.isMobile();
        });
    },
    created() {
        this.getLiveEvents();
    }
}
</script>

<style scoped>
.main {
    margin-top: 80px;
}
.d-contents {
    display: contents!important;
}
.dropdown-toggle:after {
  border: none;
}
.col-sm {
    background-color: #ddd
}
.btn .badge {
    top: 3px!important;
}
.mt-3.mb-3 .row {
    margin-left: 0;
    margin-right: 0;
}
.row-head {
    padding: .75rem;
    background-color: #212529;
    color: #fff;
    vertical-align: bottom;
    text-align: center;
}
.row-league {
    padding: .75rem;
    background-color: #ffc107;
    color: #fff;
    vertical-align: bottom;
    text-align: center;
}
.row-event {
    padding: .75rem;
    color: #fff;
    vertical-align: bottom;
    text-align: center;
}
.row-event .col.text-truncate {
    position: relative;
    z-index: 0;
    top: 3px;
}
.row-event .col.text-truncate hr {
    border-top-color: #666;
}
.row.row-item {
    background-color: #212529;
}
.row.row-item:nth-of-type(odd) {
    background-color: rgba(255,255,255,.05);
}
.row-event.row-lock {
    background-color: rgba(255, 235, 59, 0.2784313725490196);
}
.modal-edit-scroll-area {
    width: 100%;
    padding-bottom: 10px;
    overflow: hidden auto;
    height: calc(100% - 56px);
}
.modal-edit-scroll-area::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background: #4b4b4b;
    border-radius: 0;
}
.modal-edit-scroll-area::-webkit-scrollbar-thumb {
    background: #373737;
    border-radius: 0;
}
.modal-edit-close {
    color: #fff;
}
.modal-edit-close:hover {
    color: #ddd;
}
</style>>