<template>
    <main>
        <Navbar />
        <div class="main">
            <div class="container">
                <div class="btn-group w-100" role="group" aria-label="Button group with nested dropdown">
                    <button type="button" class="btn btn-secondary" @click.prevent="selectSport(1)" :class="{'active': sport == 1}" :disabled="sport == 1">
                        <i class="fas fa-futbol mr-2" v-show="platform == 'desktop'"></i>Futebol
                        <span class="badge badge-warning float-right" :class="{'w-100': platform == 'mobile'}" v-show="sport == 1 && events.length > 0">{{events.length}}</span>
                    </button>
                    <button type="button" class="btn btn-secondary" @click.prevent="selectSport(2)" :class="{'active': sport == 2}" :disabled="sport == 2">
                        <i class="fas fa-basketball-ball mr-2" v-show="platform == 'desktop'"></i>Basquete
                        <span class="badge badge-warning float-right" :class="{'w-100': platform == 'mobile'}" v-show="sport == 2 && events.length > 0">{{events.length}}</span>
                    </button>
                    <button type="button" class="btn btn-secondary" @click.prevent="selectSport(3)" :class="{'active': sport == 3}" :disabled="sport == 3">
                        <i class="fas fa-boxing-glove mr-2" v-show="platform == 'desktop'"></i>Lutas
                        <span class="badge badge-warning float-right" :class="{'w-100': platform == 'mobile'}" v-show="sport == 3 && events.length > 0">{{events.length}}</span>
                    </button>
                    <div class="btn-group" role="group">
                        <button id="btnGroupDrop1" type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fas fa-ellipsis-v mr-2"></i>Mais
                        </button>
                        <div class="dropdown-menu drop-left" aria-labelledby="btnGroupDrop1">
                            <a class="dropdown-item" href="javascript:void(0);" @click.prevent="selectSport(4)"><i class="fas fa-hockey-puck mr-2"></i>Hóquei</a>
                            <a class="dropdown-item" href="javascript:void(0);" @click.prevent="selectSport(5)"><i class="fas fa-volleyball-ball mr-2"></i>Voleibol</a>
                            <a class="dropdown-item" href="javascript:void(0);" @click.prevent="selectSport(6)"><i class="fas fa-tennis-ball mr-2"></i>Tênis</a>
                            <a class="dropdown-item" href="javascript:void(0);" @click.prevent="selectSport(7)"><i class="fas fa-gamepad-alt mr-2"></i>E-Sports</a>
                            <a class="dropdown-item" href="javascript:void(0);" @click.prevent="inplayEvents()"><i class="fas fa-tv mr-2"></i>Ao vivo</a>
                        </div><!-- /dropdown-menu -->
                    </div><!-- /btn-group -->
                </div><!-- /btn-group -->
                <div class="mt-3 mb-3">
                    <div v-show="loading == true" class="alert alert-warning" role="alert">
                        <div class="alert-heading text-center mb-0">
                            <div class="spinner-border mr-2 align-middle" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <span>Carregando, aguarde...</span>
                        </div>
                    </div><!-- /alert-warning -->
                    <div v-show="error.length > 0" class="alert alert-danger" role="alert">
                        <h4 class="alert-heading">Ops, ocorreu um problema!</h4>
                        <hr />
                        <p class="mb-0">{{error}}</p>
                    </div><!-- /alert-danger -->
                    <div v-show="events.length == 0 && nothing_found == true" class="alert alert-info" role="alert">
                        <h4 class="alert-heading">Ops, ocorreu um problema!</h4>
                        <hr />
                        <p class="mb-0">Nenhum evento encontrado, tente novamente!</p>
                        <hr />
                        <div class="form-group mb-0">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <div class="input-group-text">Nova busca</div>
                                </div>
                                <input class="form-control" type="date" v-model="newdate" @change="searchDayEvents">
                            </div>
                        </div>
                    </div><!-- /alert-danger -->
                    <div v-show="events.length > 0 && nothing_found == false">
                        <div class="row ml-0 mb-3 mr-0">
                            <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                                <select class="form-control" :class="{'mb-3': platform == 'mobile'}" @change="searchLeague">
                                    <option value="" selected>Selecione uma liga</option>
                                    <option v-for="(item, index) in leaguesOrganizes(events)" :key="index" :value="item.liga">
                                        {{item.liga}}
                                    </option>
                                </select>
                            </div><!-- /col -->
                            <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                                <input class="form-control" type="text" v-model="search" :class="{'mb-3': platform == 'mobile'}" placeholder="Buscar time ou liga...">
                            </div><!-- /col -->
                            <div class="col pl-0 pr-0" :class="{'d-contents': platform == 'mobile'}">
                                <input class="form-control" type="date" id="date" @change="searchDayEvents">
                            </div><!-- /col -->
                        </div><!-- /row -->
                        <div class="row row-head w-100">
                            <div class="col text-truncate">
                                <i class="fas fa-calendar-alt mr-2"></i>
                                <span v-show="platform == 'desktop'">Horário</span>
                            </div><!-- /col -->
                            <div class="col text-truncate">
                                <i class="fas fa-long-arrow-alt-down mr-2"></i>
                                <span v-show="platform == 'desktop'">Mandante</span>
                            </div><!-- /col -->
                            <div class="col text-truncate">
                                <i class="fas fa-long-arrow-alt-up mr-2"></i>
                                <span v-show="platform == 'desktop'">Visitante</span>
                            </div><!-- /col -->
                            <div class="col text-truncate">
                                <i class="fas fa-cogs mr-2"></i>
                                <span v-show="platform == 'desktop'">Opções</span>
                            </div><!-- /col -->
                        </div><!-- /row -->
                        <template v-if="filteredListEvents.length == 0">
                            <div class="alert alert-info mt-3" role="alert">
                                <h4 class="alert-heading">Ops, ocorreu um problema!</h4>
                                <hr />
                                <p class="mb-0">Nenhum evento encontrado, tente novamente!</p>
                            </div><!-- /alert-danger -->                            
                        </template>
                        <template v-else>
                            <div class="row row-item w-100" v-for="(item, index) in filteredListEvents" :key="index">
                                <template v-if="index == 0">
                                    <div class="row row-league w-100">
                                        <template v-if="sport == 1">
                                            <div class="col text-dark text-truncate font-weight-bold pt-1" :class="{'text-left': platform == 'mobile', 'ml-5': platform == 'desktop'}">
                                                {{item.liga}}
                                            </div>
                                            <div class="col-auto pl-0">
                                                <button class="btn btn-sm btn-block btn-danger" @click="locksAndUnlocksLeague(item.liga_id)" title="Bloquear/Desbloquear liga e eventos!">
                                                    <i class="fab fa-bandcamp"></i>
                                                </button>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="col text-dark text-truncate font-weight-bold text-center">
                                                {{item.liga}}
                                            </div>
                                        </template>
                                    </div>
                                </template>
                                <template v-else-if="item['liga_id'] !== filteredListEvents[index - 1]['liga_id']">
                                    <div class="row row-league w-100">
                                        <template v-if="sport == 1">
                                            <div class="col text-dark text-truncate font-weight-bold pt-1" :class="{'text-left': platform == 'mobile', 'ml-5': platform == 'desktop'}">
                                                {{item.liga}}
                                            </div>
                                            <div class="col-auto pl-0">
                                                <button class="btn btn-sm btn-block btn-danger" @click="locksAndUnlocksLeague(item.liga_id)" title="Bloquear/Desbloquear liga e eventos!">
                                                    <i class="fab fa-bandcamp"></i>
                                                </button>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="col text-dark text-truncate font-weight-bold text-center">
                                                {{item.liga}}
                                            </div>
                                        </template>
                                    </div>
                                </template>
                                <div class="row row-event w-100" :class="{'d-block': platform == 'mobile', 'row-lock': item.bloqueado == 1}">
                                    <div class="col text-truncate" :class="{'text-danger': platform == 'mobile'}">
                                        <span>{{item.horario | formatTimeTable}}</span>
                                        <hr v-if="platform == 'mobile'" />
                                    </div>
                                    <div class="col text-truncate">{{item.time_casa}}</div>
                                    <div class="col text-truncate">{{item.time_fora}}</div>
                                    <div class="col" :class="{'mt-3': platform == 'mobile'}">
                                        <button :class="{'btn-block': platform == 'mobile'}" :id="`btnGroupDropMenuID-${item._id}`" type="button" class="btn btn-secondary btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i class="fas fa-ellipsis-v mr-2"></i>Mais
                                        </button>
                                        <div class="dropdown-menu" :aria-labelledby="`btnGroupDropMenuID-${item._id}`">
                                            <a class="dropdown-item" href="javascript:void(0);" @click="editEvent(item._id)"><i class="fas fa-edit mr-2"></i>Editar</a>
                                            <a class="dropdown-item" href="javascript:void(0);" @click="locksAndUnlocks(item._id, item.bloqueado)">
                                                <template v-if="item.bloqueado == 2">
                                                    <i class="fas fa-lock mr-2"></i>Bloquear
                                                </template>
                                                <template v-else>
                                                    <i class="fas fa-unlock mr-2"></i>Desbloquear
                                                </template>
                                            </a>
                                            <a class="dropdown-item" href="javascript:void(0);" @click="editOdds(item._id)"><i class="fas fa-exchange-alt fa-rotate-90 mr-2"></i>Cotações</a>
                                        </div><!-- /dropdown-menu -->
                                    </div><!-- /col -->
                                </div><!-- /row -->
                            </div><!-- /row -->
                        </template>
                    </div><!-- /div -->
                </div><!-- /mb-3 -->
            </div><!-- /container -->
        </div><!-- /div -->
        <modal name="edit-odds" :width="platform == 'desktop' ? 600 : screen_w" :height="platform == 'desktop' ? 500 : screen_h" :adaptive="true" :classes="['modal-dark','modal-edit-dark']">
            <template v-if="Object.keys(modal).length">
                <header class="modal-edit-header">
                    <div class="row">
                        <div class="col-8 text-light text-uppercase mt-1">Editar odds</div>
                        <div class="col-4 text-right">
                            <div class="d-flex align-items-center justify-content-end flex-wrap">
                                <button class="btn btn-sm btn-success" @click="saveOdds">
                                    <template v-if="platform == 'desktop'">
                                        <i class="fas fa-save mr-2"></i>Salvar
                                    </template>
                                    <template v-else>
                                        <i class="fas fa-save"></i>
                                    </template>
                                </button>
                                <div class="modal-edit-close ml-3" role="button" @click="$modal.hide('edit-odds')">
                                    <i class="fal fa-times"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div class="modal-edit-scroll-area">
                    <div class="container">
                        <div class="row bg-dark">
                            <div class="col text-center text-truncate text-white">
                                <div>
                                    <img :src="`${image_server}/s/${imageShield(modal.partida.time_casa.nome)}`" @error="imageError($event, '/images/shield_default.png')" @load="imageCheck" :alt="modal.partida.time_casa.nome" border="0" width="48" height="48">
                                </div>
                                <div class="text-truncate">{{modal.partida.time_casa.nome}}</div>
                            </div>
                            <div class="col-auto text-center text-white">
                                <div class="d-flex h-100 flex-column justify-content-center align-items-center">
                                    <i class="fal fa-times"></i>
                                </div>
                            </div>
                            <div class="col text-center text-truncate text-white">
                                <div>
                                    <img :src="`${image_server}/s/${imageShield(modal.partida.time_fora.nome)}`" @error="imageError($event, '/images/shield_default.png')" @load="imageCheck" :alt="modal.partida.time_fora.nome" border="0" width="48" height="48">
                                </div>
                                <div class="text-truncate">{{modal.partida.time_fora.nome}}</div>
                            </div>
                        </div>
                        <div class="row p-0" v-for="(item, index) in modal.odds" :key="index">
                            <template v-if="isArray(item.odd)">
                                <template v-if="item.odd.length">
                                    <div class="col-12 p-0">
                                        <div class="row bg-warning p-2">
                                            <div class="col-12 mt-2 pb-2 text-dark text-uppercase text-truncate"><span class="badge badge-danger mr-2 align-text-bottom">{{item.cat_id}}</span>{{item.option}}</div>
                                        </div>
                                    </div>
                                    <div class="col-12 p-0" v-for="(option, key) in item.odd" :key="key">
                                        <!-- /OPÇÂO PLACAR -->
                                        <template v-if="item.id == 27 || item.id == 155">
                                            <div class="row rw">
                                                <div class="col-8 mt-2 text-white text-truncate">
                                                    <template v-if="option.score_home > option.score_away">
                                                        Casa ({{option.score.replace('-',':')}})
                                                    </template>
                                                    <template v-if="option.score_home == option.score_away">
                                                        Empate ({{option.score.replace('-',':')}})
                                                    </template>
                                                    <template v-else-if="option.score_home < option.score_away">
                                                        Fora ({{option.score.replace('-',':')}})
                                                    </template>
                                                </div>
                                                <div class="col-4">
                                                    <div class="d-flex flex-column">
                                                        <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text">$</div>
                                                        </div>
                                                            <input type="number" class="form-control" v-model="modal.odds[index].odd[key].odd" @keypress="updateValue($event)" autocomplete="off" spellcheck="false">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- /OPÇÂO DE JOGADOR -->
                                        <template v-else-if="item.id == 98 || item.id == 99">
                                            <div class="row rw">
                                                <div class="col-8 mt-2 text-white text-truncate">{{option.player}}</div>
                                                <div class="col-4">
                                                    <div class="d-flex flex-column">
                                                        <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text">$</div>
                                                        </div>
                                                            <input type="number" class="form-control" v-model="modal.odds[index].odd[key].odd" @keypress="updateValue($event)" autocomplete="off" spellcheck="false">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- /OPÇÂO DE ESCANTEIOS MAIS/MENOS/EXATOS -->
                                        <template v-else-if="item.id == 100 || item.id == 101 || item.id == 102">
                                            <div class="row rw">
                                                <div class="col-8 mt-2 text-white text-truncate">{{item.id == 100 ? 'Mais de ' : item.id == 101 ? 'Menos de ' : 'Exatos '}} {{option.handicap}}</div>
                                                <div class="col-4">
                                                    <div class="d-flex flex-column">
                                                        <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text">$</div>
                                                        </div>
                                                            <input type="number" class="form-control" v-model="modal.odds[index].odd[key].odd" @keypress="updateValue($event)" autocomplete="off" spellcheck="false">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- /OPÇÂO DE CARTÕES PARTIDA/CASA/FORA -->
                                        <template v-else-if="item.id == 123 || item.id == 124 || item.id == 125 || item.id == 126 || item.id == 127 || item.id == 128">
                                            <div class="row rw">
                                                <div class="col-8 mt-2 text-white text-truncate">{{item.id == 123 || item.id == 125 || item.id == 127 ? 'Acima de ' : 'Abaixo de '}} {{option.handicap}}</div>
                                                <div class="col-4">
                                                    <div class="d-flex flex-column">
                                                        <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text">$</div>
                                                        </div>
                                                            <input type="number" class="form-control" v-model="modal.odds[index].odd[key].odd" @keypress="updateValue($event)" autocomplete="off" spellcheck="false">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- /OPÇÂO DE TOTAL DE PONTOS PARTIDA/CASA/FORA -->
                                        <template v-else-if="item.id == 235 || item.id == 236 || item.id == 237 || item.id == 238 || item.id == 239 || item.id == 240">
                                            <div class="row rw">
                                                <div class="col-8 mt-2 text-white text-truncate">{{item.id == 235 || item.id == 237 || item.id == 239 ? 'Mais de ' : 'Menos de '}} {{option.handicap}}</div>
                                                <div class="col-4">
                                                    <div class="d-flex flex-column">
                                                        <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text">$</div>
                                                        </div>
                                                            <input type="number" class="form-control" v-model="modal.odds[index].odd[key].odd" @keypress="updateValue($event)" autocomplete="off" spellcheck="false">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </template>
                            </template>
                            <template v-else>
                                <template v-if="index == 0">
                                    <div class="col-12 p-0">
                                        <div class="row bg-warning p-2">
                                            <div class="col-12 mt-2 pb-2 text-dark text-uppercase text-truncate"><span class="badge badge-danger mr-2 align-text-bottom">{{item.cat_id}}</span>{{item.cat_name}}</div>
                                        </div>
                                    </div>
                                 </template>
                                 <template v-else-if="item.cat_name != modal.odds[index - 1].cat_name">
                                    <div class="col-12 p-0">
                                        <div class="row bg-warning p-2">
                                            <div class="col-12 mt-2 pb-2 text-dark text-uppercase text-truncate"><span class="badge badge-danger mr-2 align-text-bottom">{{item.cat_id}}</span>{{item.cat_name}}</div>
                                        </div>
                                    </div>
                                 </template>
                                 <div class="w-100">
                                     <div class="row">
                                        <div class="col-8 mt-2 text-white text-truncate">{{modal.odds[index].option}}</div>
                                        <div class="col-4">
                                            <div class="d-flex flex-column">
                                                <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">$</div>
                                                </div>
                                                    <input type="number" class="form-control" v-model="modal.odds[index].odd" @keypress="updateValue($event)" autocomplete="off" spellcheck="false">
                                                </div>
                                            </div>
                                        </div>
                                     </div>
                                 </div>
                            </template>
                        </div>
                    </div>
                </div>
            </template>
        </modal>
      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></loading>
    </main><!-- /main -->
</template>

<script>
import Vue from 'vue'
import {api} from '../api'
import Navbar from './Navbar'
import Swal from 'sweetalert2'
import VModal from 'vue-js-modal'
import moment from 'moment-timezone'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

Vue.use(VModal)

const today = moment().tz('America/Recife').format('YYYY-MM-DD');

export default {
    data() {
        return {
            sport: 0,
            error: '',
            search: '',
            modal: {},
            events: [],
            newdate: today,
            loading: false,
            isLoading: false,
            nothing_found: false,
            screen_w: window.screen.width,
            screen_h: window.screen.height,
            platform: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop',
            network_erros: {
				0: {
					message: 'Não há conexão com a internet!'
                },
                401: {
                    message: 'Você não tem autorização para realizar essa operação!'
                },
				404: {
					message: 'Página solicitada não foi encontrada!'
				},
				500: {
					message: 'O servidor está passando por problemas técnicos!'
				},
				408: {
					message: 'Por favor, verifique sua conexão de rede e tente novamente!'
				}					
			}
        }
    },
    components: {
        Navbar,
        Loading
    },
    methods: {
        locksAndUnlocks(id, value) {

            const self = this;
            const type = value == 2 ? 1 : 2;
            const label = type == 1 ? 'Bloquear' : 'Desbloquear';
            const index = self.events.findIndex((obj => obj._id == id));

            const event = self.events[index];

            Swal.fire({
				title: `${label} evento`,
				html: `Você deseja mesmo ${label.toLowerCase()} <p class="mb-0"><b>"${event.time_casa} X ${event.time_fora}"</b> ?</p>`,
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				cancelButtonText: 'Cancelar',
				confirmButtonText: 'Sim'
			}).then((result) => {

                if (result.value) {

                    Swal.fire({
                        showCancelButton: false,
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        text: 'Salvando alterações...',
                        onBeforeOpen: () => {
                            Swal.showLoading()  
                        }
                    });

                    api.put(`dashboard/lock-unlock-event/${id}/${type}`).then((response) => {
                        switch(response.data.result) {
                            case 'success':
                                event.bloqueado = type;

                                Swal.fire(
                                    'Parabéns!',
                                    `O evento foi ${type == 1 ? 'bloqueado' : 'debloqueado'} com sucesso!`,
                                    'success'
                                );
                            break;
                            default:
                                Swal.fire(
                                    'Falha',
                                    response.data.message,
                                    'error'
                                );
                        }
                    }).catch((error) => {
                        try {
                            Swal.fire(
                                'Falha',
                                self.network_erros[error.status]['message'],
                                'warning'
                            );
                        } catch(e) {
                            Swal.fire(
                                'Falha',
                                self.network_erros[408]['message'],
                                'warning'
                            );
                        }
                    });
                }
            });
        },
        locksAndUnlocksLeague(id) {

            const self = this;

            self.isLoading = true;

            api.put(`dashboard/lock-unlock-league/${id}`).then((response) => {
                switch(response.data.result) {
                    case 'success':

                        self.updateEvents(id, response.data.type);

                        Swal.fire(
                            'Parabéns!',
                            `A liga foi ${response.data.type == 1 ? 'bloqueada' : 'desbloqueada'} com sucesso!`,
                            'success'
                        );
                    break;
                    default:
                        Swal.fire(
                            'Falha',
                            response.data.message,
                            'error'
                        );
                }
            }).catch((error) => {
                try {
                    Swal.fire(
                        'Falha',
                        self.network_erros[error.status]['message'],
                        'warning'
                    );
                } catch(e) {
                    Swal.fire(
                        'Falha',
                        self.network_erros[408]['message'],
                        'warning'
                    );
                }
            }).finally(() => {
                self.isLoading = false;
            });
        },
        updateEvents(id, type) {

            try {
                for (const event of this.events) {
                    if (event.liga_id == id) {
                        event.bloqueado = type;
                    }
                }
            } catch(e) {
                window.console.log(e.message)
            }
        },
        selectSport(sport_id) {

            const self = this;

            self.error = '',
            self.search = '',
            self.events = [];
            self.loading = true;
            self.sport = sport_id;
            self.nothing_found = false;

            document.getElementById('date').value = today;

            api.get(`dashboard/events/${today}/sport/${sport_id}`).then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.events = response.data.events.sort((a, b) => {

                            if (self.removeAccents(a.liga) < self.removeAccents(b.liga)) return -1;
                            if (self.removeAccents(a.liga) > self.removeAccents(b.liga)) return 1;

                            if (a.horario < b.horario) return -1;
                            if (a.horario > b.horario) return 1;

                            return 0;
                        });
                    break;
                    case 'nothing_found':
                        self.nothing_found = true;
                    break;
                    default:
                        self.error = response.data.message;
                }
            }).catch((error) => {
                try {
                    self.error = self.network_erros[error.status]['message'];
                } catch(e) {
                    self.error = self.network_erros[408]['message'];
                }
            }).finally(() => {
                self.loading = false;
            });
        },
        inplayEvents() {

            const self = this;

            self.error = '',
            self.search = '',
            self.events = [];
            self.loading = true;
            self.nothing_found = false;

            document.getElementById('date').value = today;

            api.get(`dashboard/events/${today}/sport/1/live`).then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.events = response.data.events.sort((a, b) => {

                            if (self.removeAccents(a.liga) < self.removeAccents(b.liga)) return -1;
                            if (self.removeAccents(a.liga) > self.removeAccents(b.liga)) return 1;

                            if (a.horario < b.horario) return -1;
                            if (a.horario > b.horario) return 1;

                            return 0;
                        });
                    break;
                    case 'nothing_found':
                        self.nothing_found = true;
                    break;
                    default:
                        self.error = response.data.message;
                }
            }).catch((error) => {
                try {
                    self.error = self.network_erros[error.status]['message'];
                } catch(e) {
                    self.error = self.network_erros[408]['message'];
                }
            }).finally(() => {
                self.loading = false;
            });
        },
        leaguesOrganizes(arr) {
            return arr.reduce((unique, o) => {
                if(!unique.some(obj => obj.liga_id === o.liga_id)) {
                    unique.push(o);
                }
                return unique;
            },[]).sort((a, b) => {
                return this.removeAccents(a.liga).localeCompare(this.removeAccents(b.liga))
            });
        },
        searchLeague(event) {
           this.search = event.target.value;
        }, 
        editOdds(id) {

            const self = this;

            self.isLoading = true;

            api.get(`/odds/${id}`).then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.modal = response.data;
                        self.$modal.show('edit-odds', {
                            id: id
                        });
                    break;
                    default:
                        Swal.fire(
                            'Atenção!',
                            response.data.message,
                            'warning'
                        );
                }
            }).catch((error) => {
                try {
                    Swal.fire(
                        'Falha',
                        self.network_erros[error.status]['message'],
                        'error'
                    );
                } catch(e) {
                    Swal.fire(
                        'Falha',
                        self.network_erros[408]['message'],
                        'error'
                    );
                }
            }).finally(() => {
                self.isLoading = false;
            });
        },
        async saveOdds() {

            const self = this;
            const odds = await self.checkOdds();

            if (!odds) return;

            Swal.fire({
				title: 'Confirmação',
				html: `Você deseja mesmo alterar as odds do evento? <hr /> <p class="font-weight-bold">${self.modal.partida.time_casa.nome} X ${self.modal.partida.time_fora.nome}</p> <hr /> Lembrando que após a confirmação, esse evento não irá mais receber atualizações das odds automaticamente.`,
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				cancelButtonText: 'Cancelar',
				confirmButtonText: 'Sim'
			}).then((result) => {

                if (result.value) {

                    self.isLoading = true;

                    api.post('dashboard/event/edit/odds', {
                        odds: odds,
                        id: self.modal.id
                    }).then((response) => {
                        switch(response.data.result) {
                            case 'success':
                                Swal.fire(
                                    'Parabéns!',
                                    'As alterações foram salvas com sucesso!',
                                    'success'
                                );
                            break;
                            default:
                                Swal.fire(
                                    'Atenção!',
                                    response.data.message,
                                    'warning'
                                );
                        }
                    }).catch((error) => {
                        try {
                            Swal.fire(
                                'Falha',
                                self.network_erros[error.status]['message'],
                                'error'
                            );
                        } catch(e) {
                            Swal.fire(
                                'Falha',
                                self.network_erros[408]['message'],
                                'error'
                            );
                        }
                    }).finally(() => {
                        self.isLoading = false;
                    });
                }
            });
        },
        async checkOdds() {

            try {

                this.$modal.hide('edit-odds');

                for (let key in this.modal.odds) {
                    let item = this.modal.odds[key];

                    if (Array.isArray(item.odd)) {

                        if (item.odd.length) {

                            for (let j in item.odd) {
                                let option = item.odd[j];

                                if (option.odd == '' || option.odd < 1) {
                                    this.modal.odds[key].odd = '1.00';
                                }
                            }
                        }
                    }
                    else if(item.odd == '' || item.odd < 1)
                    {
                        this.modal.odds[key].odd = '1.00';
                    }
                }

                return this.modal.odds;
            } 
            catch(e) 
            {
                Swal.fire(
                    'Erro!',
                    'Preencha todos os campos, antes de salvar!',
                    'error'
                );
            }
        },
        searchDayEvents(event) {

            const self = this;

            self.error = '',
            self.search = '',
            self.events = [];
            self.loading = true;
            self.nothing_found = false;
            self.sport = self.sport == 0 ? 1 : self.sport;
            
            api.get(`dashboard/events/${event.target.value}/sport/${self.sport}`).then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.events = response.data.events.sort((a, b) => {

                            if (self.removeAccents(a.liga) < self.removeAccents(b.liga)) return -1;
                            if (self.removeAccents(a.liga) > self.removeAccents(b.liga)) return 1;

                            if (a.horario < b.horario) return -1;
                            if (a.horario > b.horario) return 1;

                            return 0;
                        });
                    break;
                    case 'nothing_found':
                        //self.sport = 0;
                        self.nothing_found = true;
                    break;
                    default:
                        self.error = response.data.message;
                }
            }).catch((error) => {
                try {
                    self.error = self.network_erros[error.status]['message'];
                } catch(e) {
                    self.error = self.network_erros[408]['message'];
                }
            }).finally(() => {
                self.loading = false;
            });
        },
        async editEvent(id) {
            
            const self = this;
            const index = self.events.findIndex((obj => obj._id == id));

            try {

                const event = self.events[index];
                const hour = moment(event.horario).format('HH:mm');
                const date = moment(event.horario).format('YYYY-MM-DD');
    
                const { value: formValues } = await Swal.fire({
                    title: 'Editar evento',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Salvar',
                    html: `
                        <label class="d-block text-left mb-0">Mandante</label>
                        <input id="swal-input1" class="swal2-input" value="${event.time_casa}" autocomplete="off" spellcheck="false">

                        <label class="d-block text-left mb-0">Visitante</label>
                        <input id="swal-input2" class="swal2-input" value="${event.time_fora}" autocomplete="off" spellcheck="false">

                        <label class="d-block text-left mb-0">Data do evento</label>
                        <input id="swal-input3" class="swal2-input" type="date" value="${date}" autocomplete="off" spellcheck="false">

                        <label class="d-block text-left mb-0">Hora do evento</label>
                        <input id="swal-input4" class="swal2-input" type="time" value="${hour}" autocomplete="off" spellcheck="false">

                        <label class="d-block text-left mb-0">Atualizar odds automaticamente</label>
                        <select id="swal-input5" class="swal2-input w-100">
                            <option value="1" ${event.automatico == 1 ? 'selected' : ''}>Sim</option>
                            <option value="2" ${event.automatico == 2 ? 'selected' : ''}>Não</option>
                        </select>

                        <label class="d-block text-left mb-0">Permitir apostas ao vivo nesse evento</label>
                        <select id="swal-input6" class="swal2-input w-100">
                            <option value="1" ${event.ao_vivo == '1' ? 'selected' : ''}>Sim</option>
                            <option value="2" ${event.ao_vivo == '2' ? 'selected' : ''}>Não</option>
                        </select>
                    `,
                    focusConfirm: false,
                    preConfirm: () => {
                        return {
                            home: document.getElementById('swal-input1').value.trim(),
                            away: document.getElementById('swal-input2').value.trim(),
                            date: document.getElementById('swal-input3').value.trim(),
                            hour: document.getElementById('swal-input4').value.trim(),
                            auto: document.getElementById('swal-input5').value.trim(),
                            live: document.getElementById('swal-input6').value.trim()
                        }
                    },
                    onOpen: () => {
                        document.getElementById("swal-input1").addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^a-zà-ú0-9\-\s]+/gi, '');
                        });

                        document.getElementById("swal-input2").addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^a-zà-ú0-9\-\s]+/gi, '');
                        });
                    }
                });

                if (formValues.home == '') {
                    Swal.fire(
                        'Atenção!',
                        'Informe o nome do mandante!',
                        'warning'
                    );
                } else if (formValues.away == '') {
                    Swal.fire(
                        'Atenção!',
                        'Informe o nome do visitante!',
                        'warning'
                    );
                } else if (formValues.date == '') {
                    Swal.fire(
                        'Atenção!',
                        'Informe a data do evento!',
                        'warning'
                    );
                } else if (formValues.hour == '') {
                    Swal.fire(
                        'Atenção!',
                        'Informe a hora do evento!',
                        'warning'
                    );
                }
                else
                {
                    event.data = formValues.date;
                    event.ao_vivo = formValues.live;
                    event.time_casa = formValues.home;
                    event.time_fora = formValues.away;
                    event.automatico = formValues.auto;
                    event.horario = moment([formValues.date, formValues.hour].join(' ')).tz('America/Recife').format();

                    Swal.fire({
                        showCancelButton: false,
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        text: 'Salvando alterações...',
                        onBeforeOpen: () => {
                            Swal.showLoading()  
                        }
                    });

                    api.post('dashboard/edit-event', {
                        id: id,
                        form: formValues
                    }).then((response) => {
                        switch (response.data.result) {
                            case 'success':
                                Swal.fire(
                                    'Parabéns!',
                                    'As alterações foram salvas com sucesso!',
                                    'success'
                                );
                            break;
                            default:
                                Swal.fire(
                                    'Falha!',
                                    'Não foi posssível salvar as alterações!',
                                    'error'
                                );
                        }
                    }).catch((error) => {
                        try {
                            Swal.fire(
                                'Falha',
                                self.network_erros[error.status]['message'],
                                'warning'
                            );
                        } catch(e) {
                            Swal.fire(
                                'Falha',
                                self.network_erros[408]['message'],
                                'warning'
                            );
                        }
                    });
                }
            } 
            catch(e) 
            {
                // window.console.warn(e.message);
            }
        },
        updateValue(event) {
           let value = event.target.value;

           value = value.replace(/\D/g,"");  
           value = value.replace(/[0-9]{12}/," ");  
           value = value.replace(/(\d{1})(\d{8})$/,"$1.$2");
           value = value.replace(/(\d{1})(\d{5})$/,"$1.$2");  
           value = value.replace(/(\d{1})(\d{1,2})$/,"$1.$2"); 
        
           event.target.value = value.replace(',','.');
        },
        updateOptionValue(event) {
           event.target.value = event.target.value.trim().replace(/[^a-zà-ú0-9\\!?.,-\s]+/gi,'');
        },
        isArray(obj) {
            return Array.isArray(obj);
        },
        removeAccents(str) {
            
            let string = str;
            let map = {a : /[\xE0-\xE6]/g, A : /[\xC0-\xC6]/g, e : /[\xE8-\xEB]/g, E : /[\xC8-\xCB]/g, i : /[\xEC-\xEF]/g, I : /[\xCC-\xCF]/g, o : /[\xF2-\xF6]/g, O : /[\xD2-\xD6]/g, u : /[\xF9-\xFC]/g, U : /[\xD9-\xDC]/g, c : /\xE7/g, C : /\xC7/g, n : /\xF1/g, N : /\xD1/g};

            for (let key in map) {
                 let rex = map[key];
                 string = string.replace(rex, key);
            }

            return string;
        },
        imageError(event, image) {
            event.target.src = image;
        },
        imageShield(team) {
            try {
                return `${this.removeAccents(team).toLowerCase().trim().replace(/[^a-z-0-9\s]/g, '').replace(/\s/g, '-')}.png`;
            } catch(e) {
                return 'shield.png';
            }
        },
        imageCheck(event) {
            try {
                var w = event.target.naturalWidth;
                var h = event.target.naturalHeight;

                if (w == 1 && h == 1) {
                    event.target.src = 'shield.png';
                }
            } catch(e) {
                event.target.src = 'shield.png';
            }
        },
    },
    watch: {
        sport() {
            this.newdate = today;
        },
        search(value) {
            if (value.trim().length == 0) this.search = '';
        }
    },
    filters: {
        formatTimeTable(time) {
            return moment(time).tz('America/Recife').format("DD/MM HH:mm");
        }
    },
    computed: {
        filteredListEvents() {
            return this.events.filter((item) => {
                return item.liga.toLowerCase().trim().includes(this.search.toLowerCase().trim()) || item.time_casa.toLowerCase().trim().includes(this.search.toLowerCase().trim()) || item.time_fora.toLowerCase().trim().includes(this.search.toLowerCase().trim())
            });
        }
    },
    created() {
        window.addEventListener('resize', () => {
            this.platform = this.isMobile();
        });
    }
}
</script>

<style scoped>
.main {
    margin-top: 80px;
}
.dropdown-toggle:after {
  border: none;
}
.col-sm {
    background-color: #ddd
}
.btn .badge {
    top: 3px!important;
}
.mt-3.mb-3 .row {
    margin-left: 0;
    margin-right: 0;
}
.row-head {
    padding: .75rem;
    background-color: #212529;
    color: #fff;
    vertical-align: bottom;
    text-align: center;
}
.row-league {
    padding: .75rem;
    background-color: #ffc107;
    color: #fff;
    vertical-align: bottom;
    text-align: center;
}
.row-event {
    padding: .75rem;
    color: #fff;
    vertical-align: bottom;
    text-align: center;
}
.row-event .col.text-truncate {
    position: relative;
    z-index: 0;
    top: 3px;
}
.row-event .col.text-truncate hr {
    border-top-color: #666;
}
.row.row-item {
    background-color: #212529;
}
.row.row-item:nth-of-type(odd) {
    background-color: rgba(255,255,255,.05);
}
.row-event.row-lock {
    background-color: rgba(255, 235, 59, 0.2784313725490196);
}
.d-contents {
    display: contents!important;
}
input[type=date]::-webkit-clear-button {
    display: none; /* Hide the button */
    -webkit-appearance: none; /* turn off default browser styling */
}
input[type=date]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    display: none;
}
.modal-edit-scroll-area {
    width: 100%;
    padding-bottom: 10px;
    overflow: hidden auto;
    height: calc(100% - 56px);
}
.modal-edit-scroll-area::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background: #4b4b4b;
    border-radius: 0;
}
.modal-edit-scroll-area::-webkit-scrollbar-thumb {
    background: #373737;
    border-radius: 0;
}
.modal-edit-close {
    color: #fff;
}
.modal-edit-close:hover {
    color: #ddd;
}
.drop-left {
    margin-left: -100%!important;
}
</style>